import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
    },
    colors: {
      navbar: {
        light: {
          backgroundColor: "#ffffff",
          color: "#171E26",
        },
        dark: {
          backgroundColor: "#171E26",
          color: "#ffffff",
        },
      },
      button: {
        light: {
          backgroundColor: "#38118C",
          color: "#ffffff",
        },
        dark: {
          backgroundColor: "#41E1F2",
          color: "#171E26",
        },
      },
      clearButton: {
        light: {
          backgroundColor: "#F263DA",
          color: "#ffffff",
        },
        dark: {
          backgroundColor: "#F263DA",
          color: "#ffffff",
        },
      },
      hoverLink: {
        light: {
          backgroundColor: "#171E26",
          color: "#ffffff",
        },
        dark: {
          backgroundColor: "#ffffff",
          color: "#171E26",
        },
      },
      footer: {
        light: {
          backgroundColor: "#ffffff",
          color: "#171E26",
        },
        dark: {
          backgroundColor: "#171E26",
          color: "#ffffff",
        },
      }
    }
  });
  