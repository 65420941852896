import React, { useState, useRef } from 'react';
import { Box, ChakraProvider, Flex } from '@chakra-ui/react';
import Form from './components/Form';
import Preview from './components/Preview';
import HtmlGenerator from './components/HtmlGenerator';
import Navbar from './components/Navbar';
import { theme } from './styles/Theme';
import Footer from './components/Footer';

function App() {
  const [formData, setFormData] = useState({
    name: '',
    function: '',
    telephone: '',
    timestamp: '',
  });

  const [cleanedName, setCleanedName] = useState('');
  const [imageRefreshKey, setImageRefreshKey] = useState(0);
  const previewRef = useRef(null);  // Ref para o Preview

  return (
    <>
      <ChakraProvider theme={theme}>
        <Navbar />
        <Flex 
          p={5} 
          direction={['column', 'row']} 
          wrap="wrap" 
          align="start" 
          justify="center"
          mt="80px"
        >
          <Box m={3} w="100%" maxW="500px">
            <Form formData={formData} setFormData={setFormData} setCleanedName={setCleanedName} setImageRefreshKey={setImageRefreshKey} />
          </Box>
          <Box m={3} w="100%" maxW="600px">
            <Preview formData={formData} cleanedName={cleanedName} ref={previewRef} />
            <HtmlGenerator formData={formData} cleanedName={cleanedName} previewRef={previewRef} key={imageRefreshKey}/>
          </Box>
        </Flex>
        <Footer />
      </ChakraProvider>
    </>
  );
}

export default App;
