import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Button,
  Image,
  IconButton,
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { AttachmentIcon, DeleteIcon } from "@chakra-ui/icons";
import PropTypes from "prop-types";
import axios from "axios";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const Form = ({ formData, setFormData, setCleanedName }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadImagePreview, setUploadImagePreview] = useState(null);
  const [errors, setErrors] = useState({
    name: true,
    function: true,
    telephone: true,
  });
  const [cropper, setCropper] = useState(null);

  const toast = useToast();
  const [uploading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnColor = useColorModeValue(
    "button.light.backgroundColor",
    "button.dark.backgroundColor"
  );
  const btnTextColor = useColorModeValue(
    "button.light.color",
    "button.dark.color"
  );
  const clearBtnColor = useColorModeValue(
    "clearButton.light.backgroundColor",
    "clearButton.dark.backgroundColor"
  );
  const clearBtnTextColor = useColorModeValue(
    "clearButton.light.color",
    "clearButton.dark.color"
  );

  const removeAccentsAndSpecialCharacters = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9 ]/g, "");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors, [name]: value.trim() === "" };

    setFormData({ ...formData, [name]: value });
    setErrors(newErrors);

    if (name === "name") {
      let cleanedName = removeAccentsAndSpecialCharacters(value).toLowerCase();
      cleanedName = cleanedName.replace(/\s/g, "_");
      setCleanedName(cleanedName);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setUploadImagePreview(URL.createObjectURL(file));
      onOpen(); // Abrir o modal para cortar a imagem
    }
  };

  const handleSaveCroppedImage = () => {
    if (cropper) {
      const canvas = cropper.getCroppedCanvas({
        width: 200,
        height: 200,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: "high",
      });

      const ctx = canvas.getContext("2d");
      const radius = canvas.width / 2;
      ctx.beginPath();
      ctx.arc(radius, radius, radius, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.clip();

      const croppedImage = canvas.toDataURL("image/png");

      setUploadImagePreview(croppedImage);
      resetFileInput(); // Limpar o input de arquivo após salvar a imagem
      onClose(); // Fechar o modal após salvar a imagem cortada
    }
  };

  const resetFileInput = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleUpload = async () => {
    if (!uploadImagePreview) return;

    let cleanedName = removeAccentsAndSpecialCharacters(formData.name).toLowerCase();
    cleanedName = cleanedName.replace(/\s/g, "_");

    const data = new FormData();
    const blob = await fetch(uploadImagePreview).then((res) => res.blob());
    data.append("image", blob, `${cleanedName}.png`);
    data.append("cleanedName", cleanedName);

    try {
      const response = await axios.post("https://upload.redware.io/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": "8XepWdrrWryiInbLEW37ZCy45BJRK0qYO2T9r66b65bff97da8",
        },
      });

      setFormData({ ...formData, timestamp: new Date().getTime().toString() });

      setCleanedName(cleanedName);

      toast({
        title: response.data.message,
        status: response.status ? "success" : "error",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      toast({
        title: "Error uploading image",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setSelectedFile(null);
      setUploadImagePreview(null);
      resetFileInput(); // Limpar o input de arquivo após o upload
    }
  };

  const handleDelete = () => {
    setSelectedFile(null);
    setUploadImagePreview(null);
    setFormData({ ...formData, timestamp: "" });
    resetFileInput(); // Limpar o input de arquivo ao deletar a imagem
  };

  const formatTelephone = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(
      /^(\d{0,2})(\d{0,2})(\d{0,1})(\d{0,4})(\d{0,4})$/
    );
    if (match) {
      return [
        match[1] ? "+" + match[1] : "",
        match[2] ? " (" + match[2] + ") " : "",
        match[3] ? match[3] + " " : "",
        match[4] ? match[4] + "-" : "",
        match[5] ? match[5] : "",
      ].join("");
    }
    return value;
  };

  return (
    <VStack spacing={6}>
      <Box
        p={5}
        shadow="md"
        borderWidth="1px"
        flex="1"
        borderRadius="md"
        w="100%"
        maxWidth="550px"
      >
        <VStack spacing={4}>
          <FormControl isInvalid={errors.name} isRequired>
            <FormLabel>Nome</FormLabel>
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && (
              <Text color="red.500" fontSize={10}>
                Este campo é obrigatório.
              </Text>
            )}
          </FormControl>
          <FormControl isInvalid={errors.function} isRequired>
            <FormLabel>Função</FormLabel>
            <Input
              type="text"
              name="function"
              value={formData.function}
              onChange={handleChange}
            />
            {errors.function && (
              <Text color="red.500" fontSize={10}>
                Este campo é obrigatório.
              </Text>
            )}
          </FormControl>
          <FormControl isInvalid={errors.telephone} isRequired>
            <FormLabel>Telefone</FormLabel>
            <Input
              type="text"
              name="telephone"
              value={formatTelephone(formData.telephone)}
              onChange={handleChange}
            />
            {errors.telephone && (
              <Text color="red.500" fontSize={10}>
                Este campo é obrigatório.
              </Text>
            )}
          </FormControl>
        </VStack>
      </Box>

      {/* Upload Image Section */}
      <Box
        p={5}
        shadow="md"
        borderWidth="1px"
        flex="1"
        borderRadius="md"
        mt={4}
        w="100%"
        maxWidth="550px"
      >
        <FormControl>
          <Flex alignItems="center" mb={4}>
            <Button
              leftIcon={<AttachmentIcon />}
              textColor={btnTextColor}
              bg={btnColor}
              variant="outline"
              mr={3}
              onClick={() => document.getElementById("fileInput").click()}
              isDisabled={errors.name} // Desabilitar botão se o nome não for informado
            >
              Selecione a Imagem
            </Button>
            <Input
              id="fileInput"
              type="file"
              display="none"
              onChange={handleFileChange}
            />
            <Input
              type="text"
              readOnly
              value={selectedFile ? selectedFile.name : ""}
              placeholder="Nenhuma img. selec."
              mr={3}
              flex="1"
            />
            {selectedFile && (
              <IconButton
                icon={<DeleteIcon />}
                textColor={clearBtnTextColor}
                bg={clearBtnColor}
                onClick={handleDelete}
                aria-label="Deletar imagem selecionada"
              />
            )}
          </Flex>
          {uploadImagePreview && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={4}
            >
              <Image
                src={uploadImagePreview}
                alt="Image Preview"
                boxSize="150px"
                borderRadius="full"
                objectFit="cover"
              />
            </Box>
          )}
          <Button
            onClick={handleUpload}
            mt={4}
            textColor={btnTextColor}
            bg={clearBtnColor}
            isLoading={uploading}
            isDisabled={!selectedFile || errors.name} // Desabilitar botão de upload se a imagem não for selecionada ou o nome não for informado
          >
            Carregar Imagem
          </Button>
        </FormControl>
      </Box>

      {/* Modal for Image Cropping */}
      <Modal isOpen={isOpen} onClose={() => { onClose(); resetFileInput(); }} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Corte a Imagem</ModalHeader>
          <ModalBody>
            {uploadImagePreview && (
              <Cropper
                src={uploadImagePreview}
                style={{ height: 400, width: "100%" }}
                aspectRatio={1}
                guides={false}
                cropBoxResizable={true}  // Permite redimensionar a área de corte
                cropBoxMovable={true}    // Permite mover a área de corte
                dragMode="move"
                viewMode={2}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
              />

            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveCroppedImage}>
              Salvar
            </Button>
            <Button variant="ghost" onClick={() => { onClose(); resetFileInput(); }}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

Form.propTypes = {
  formData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    function: PropTypes.string.isRequired,
    telephone: PropTypes.string.isRequired,
    timestamp: PropTypes.string,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  setCleanedName: PropTypes.func.isRequired,
};

export default Form;
