import React from 'react';
import { Box, Image, Text, Flex, Spacer, useColorMode, IconButton, useColorModeValue } from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';

const Navbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  
  // Use the theme colors based on the color mode
  const bgColor = useColorModeValue("navbar.light.backgroundColor", "navbar.dark.backgroundColor");
  const textColor = useColorModeValue("navbar.light.color", "navbar.dark.color");

  return (
    <Box 
      as="header" 
      w="100%" 
      h="80px" 
      bg={bgColor} 
      color={textColor}
      shadow="md" 
      display="flex" 
      alignItems="center" 
      justifyContent="center"
      p={4}
      position="fixed"
      top="0"
      zIndex="1000"
    >
      <Flex w="100%" maxW="1200px" alignItems="center">
        <Box display="flex" alignItems="center">
          <picture>
            <source 
              srcSet="https://pictures.redware.io/assinatura_email/logo_redware_113.webp 113w, https://pictures.redware.io/assinatura_email/logo_redware_226.webp 226w, https://pictures.redware.io/assinatura_email/logo_redware_339.webp 339w, https://pictures.redware.io/assinatura_email/logo_redware_452.webp 452w, https://pictures.redware.io/assinatura_email/logo_redware_565.webp 565w, https://pictures.redware.io/assinatura_email/logo_redware_678.webp 678w, https://pictures.redware.io/assinatura_email/logo_redware_791.webp 791w, https://pictures.redware.io/assinatura_email/logo_redware_904.webp 904w, https://pictures.redware.io/assinatura_email/logo_redware_1017.webp 1017w, https://pictures.redware.io/assinatura_email/logo_redware_1126.webp 1126w" 
              type="image/webp" 
              sizes="(max-width: 1126px) 100vw, 1126px" 
            />
            <Image 
              src="https://pictures.redware.io/assinatura_email/logo_redware_1126.png" 
              width="50px" 
              height="56px" 
              alt="Logo" 
            />
          </picture>
          <Text fontSize="xl" fontWeight="bold" ml={4}>
            E-mail Template Generator
          </Text>
        </Box>
        <Spacer />
        <IconButton
          icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          onClick={toggleColorMode}
          variant="ghost"
          aria-label="Toggle Theme"
        />
      </Flex>
    </Box>
  );
};

export default Navbar;