import React from 'react';
import { Box, Text, Flex, Link, Spacer, Icon, useColorModeValue } from '@chakra-ui/react';
import { FaLinkedin, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <Box 
      as="footer" 
      w="100%" 
      py={4} 
      bg={useColorModeValue("footer.light.backgroundColor", "footer.dark.backgroundColor")}
      color={useColorModeValue("footer.light.color", "footer.dark.color")}
    >
      <Flex 
        maxW="1200px" 
        mx="auto" 
        px={4} 
        alignItems="center"
      >
        <Text fontSize="sm">
          &copy; {new Date().getFullYear()} Redware Brasil. All rights reserved.
        </Text>
        <Spacer />
        <Flex alignItems="center" gridGap={4}>
          <Link href="https://www.linkedin.com/company/redware-brasil/" isExternal>
            <Icon as={FaLinkedin} boxSize={5} />
          </Link>
          <Link href="https://www.instagram.com/redwarebrasil/" isExternal>
            <Icon as={FaInstagram} boxSize={5} />
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;