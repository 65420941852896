import React from "react";
import { Box, Button, Text, List, ListItem, useColorModeValue, useToast } from "@chakra-ui/react";
import PropTypes from "prop-types";

const HtmlGenerator = ({ formData, cleanedName }) => {
  const toast = useToast();

  const btnColor = useColorModeValue("button.light.backgroundColor", "button.dark.backgroundColor");
  const btnTextColor = useColorModeValue("button.light.color", "button.dark.color");

  // Verifica se todos os campos obrigatórios estão preenchidos
  const isFormComplete = formData.name && formData.function && formData.telephone;

  const generateEmailHtml = (data) => {
    const { name, function: jobFunction, telephone, timestamp } = data;

    return `
    <div>
    <span
      style="
        font-family: Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, Calibri,
          Helvetica, sans-serif;
        font-size: 12pt;
        color: rgb(0, 0, 0);
      "
      ><br
    /></span>
  </div>
  <table
    style="
      margin: 0px;
      box-sizing: border-box;
      border-collapse: collapse;
      border-spacing: 0px;
    "
  >
    <tbody>
      <tr>
        <td style="padding-right: 1px">
          <table
            style="
              margin: 0px;
              box-sizing: border-box;
              border-collapse: collapse;
              border-spacing: 0px;
            "
          >
            <tbody>
              <tr>
                <td
                  align="center"
                  style="padding-right: 13px; vertical-align: top"
                >
                  <table
                    style="
                      margin: 0px;
                      box-sizing: border-box;
                      border-collapse: collapse;
                      border-spacing: 0px;
                    "
                  >
                    <tbody>
                      <tr>
                        <td style="padding-right: 1px">
                          <p style="margin: 0.75pt">
                            <span
                              style="
                                font-family: Aptos, sans-serif;
                                font-size: 12pt;
                              "
                              ><span>
                                <picture>
                                  <source
                                    srcset="
                                      https://pictures.redware.io/assinatura_email/${cleanedName}/80.webp?t=${timestamp}   80w,
                                      https://pictures.redware.io/assinatura_email/${cleanedName}/160.webp?t=${timestamp} 160w,
                                      https://pictures.redware.io/assinatura_email/${cleanedName}/200.webp?t=${timestamp} 200w
                                    "
                                    type="image/webp"
                                    sizes="(max-width: 200px) 100vw, 200px"
                                  />
                                  <img
                                    src="https://pictures.redware.io/assinatura_email/${cleanedName}/200.png?t=${timestamp}"
                                    width="100"
                                    height="100"
                                    v:shapes="Picture_x0020_14"
                                    uploadprocessed="true"
                                  />
                                </picture> </span
                            ></span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  style="
                    padding-right: 15px;
                    padding-left: 1px;
                    vertical-align: top;
                  "
                >
                  <table
                    style="
                      margin: 0px;
                      box-sizing: border-box;
                      border-collapse: collapse;
                      border-spacing: 0px;
                    "
                  >
                    <tbody>
                      <tr>
                        <td
                          style="
                            line-height: 21px;
                            white-space: nowrap;
                            padding-right: 1px;
                          "
                        >
                          <p
                            style="
                              line-height: 21px;
                              white-space: nowrap;
                              margin: 1px;
                              font-family: Arial, sans-serif;
                              font-size: 14px;
                              font-weight: 700;
                              color: rgb(98, 98, 98);
                            "
                          >
                            ${name}
                          </p>
                          <p
                            style="
                              line-height: 22px;
                              white-space: nowrap;
                              margin: 1px;
                              font-family: Arial, sans-serif;
                              font-size: 14px;
                              color: rgb(118, 118, 118);
                            "
                          >
                            ${jobFunction}
                          </p>
                          <p
                            style="
                              line-height: 22px;
                              white-space: nowrap;
                              margin: 1px;
                              font-family: Arial, sans-serif;
                              font-size: 14px;
                              color: rgb(118, 118, 118);
                            "
                          >
                            Redware Brasil
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  style="
                    border-right: 2px solid rgb(118, 118, 118);
                    padding-top: 1px;
                  "
                ></td>
                <td
                  style="
                    padding-right: 1px;
                    padding-left: 15px;
                    vertical-align: top;
                  "
                >
                  <table
                    style="
                      margin: 0px;
                      box-sizing: border-box;
                      border-collapse: collapse;
                      border-spacing: 0px;
                    "
                  >
                    <tbody>
                      <tr>
                        <td style="padding-right: 1px">
                          <table
                            style="
                              margin: 0px;
                              box-sizing: border-box;
                              border-collapse: collapse;
                              border-spacing: 0px;
                            "
                          >
                            <tbody>
                              <tr>
                                <td
                                  style="
                                    padding-top: 1px;
                                    padding-right: 5px;
                                    padding-bottom: 1px;
                                    vertical-align: middle;
                                  "
                                >
                                  <p style="margin: 0.75pt">
                                    <span
                                      style="
                                        font-family: Aptos, sans-serif;
                                        font-size: 12pt;
                                      "
                                      ><span>
                                        <picture>
                                          <source
                                            srcset="
                                              https://pictures.redware.io/assinatura_email/telefone_60.webp 60w
                                            "
                                            type="image/webp"
                                            sizes="(max-width: 60px) 100vw, 60px"
                                          />
                                          <img
                                            src="https://pictures.redware.io/assinatura_email/telefone_60.png"
                                            width="20"
                                            height="20"
                                            v:shapes="Picture_x0020_13"
                                            uploadprocessed="true"
                                          />
                                        </picture> </span
                                    ></span>
                                  </p>
                                </td>
                                <td
                                  style="
                                    line-height: 21px;
                                    white-space: nowrap;
                                    padding-top: 1px;
                                    padding-bottom: 1px;
                                    vertical-align: middle;
                                    color: rgb(118, 118, 117);
                                  "
                                >
                                  <p style="line-height: 15.75pt; margin: 0.75pt">
                                    <a href="tel:${telephone}" target="_blank"
                                      ><span
                                        style="
                                          color: #767676;
                                          font-family: 'Arial', sans-serif;
                                          font-size: 10.5pt;
                                        "
                                        ><span
                                          style="
                                            text-decoration: none;
                                            text-underline: none;
                                          "
                                          >${telephone}</span
                                        ></span
                                      ></a
                                    >
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    padding-top: 1px;
                                    padding-right: 5px;
                                    padding-bottom: 1px;
                                    vertical-align: middle;
                                  "
                                >
                                  <p style="margin: 0.75pt">
                                    <span
                                      style="
                                        font-family: Aptos, sans-serif;
                                        font-size: 12pt;
                                      "
                                      ><span>
                                        <picture>
                                          <source
                                            srcset="
                                              https://pictures.redware.io/assinatura_email/localizacao_60.webp 60w
                                            "
                                            type="image/webp"
                                            sizes="(max-width: 60px) 100vw, 60px"
                                          />
                                          <img
                                            src="https://pictures.redware.io/assinatura_email/localizacao_60.png"
                                            width="20"
                                            height="20"
                                            border="0"
                                            v:shapes="Picture_x0020_12"
                                            uploadprocessed="true"
                                          />
                                        </picture> </span
                                    ></span>
                                  </p>
                                </td>
                                <td
                                  style="
                                    line-height: 21px;
                                    white-space: nowrap;
                                    padding-top: 1px;
                                    padding-bottom: 1px;
                                    vertical-align: middle;
                                    color: rgb(118, 118, 117);
                                  "
                                >
                                  <p
                                    style="
                                      line-height: 21px;
                                      white-space: nowrap;
                                      margin: 1px;
                                    "
                                  >
                                    <span
                                      style="
                                        font-family: Arial, sans-serif;
                                        font-size: 14px;
                                        color: rgb(118, 118, 118);
                                        line-height: 21px;
                                      "
                                      >Saldanha Marinho, 546 - Passo
                                      Fundo/RS</span
                                    >
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    padding-top: 1px;
                                    padding-right: 5px;
                                    padding-bottom: 1px;
                                    vertical-align: middle;
                                  "
                                >
                                  <p style="margin: 0.75pt">
                                    <span
                                      style="
                                        font-family: Aptos, sans-serif;
                                        font-size: 12pt;
                                      "
                                      ><span>
                                        <picture>
                                          <source
                                            srcset="
                                              https://pictures.redware.io/assinatura_email/site_60.webp 60w
                                            "
                                            type="image/webp"
                                            sizes="(max-width: 60px) 100vw, 60px"
                                          />
                                          <img
                                            src="https://pictures.redware.io/assinatura_email/site_60.png"
                                            width="20"
                                            height="20"
                                            border="0"
                                            v:shapes="Picture_x0020_11"
                                            uploadprocessed="true"
                                          />
                                        </picture> </span
                                    ></span>
                                  </p>
                                </td>
                                <td
                                  style="
                                    line-height: 21px;
                                    white-space: nowrap;
                                    padding-top: 1px;
                                    padding-bottom: 1px;
                                    vertical-align: middle;
                                    color: rgb(98, 98, 97);
                                  "
                                >
                                  <p
                                    style="
                                      line-height: 21px;
                                      white-space: nowrap;
                                      margin: 1px;
                                    "
                                  >
                                    <span
                                      style="
                                        font-family: Arial, sans-serif;
                                        font-size: 14px;
                                        color: rgb(98, 98, 98);
                                        line-height: 21px;
                                        font-weight: 700;
                                      "
                                      ><a
                                        href="https://redware.io"
                                        target="_blank"
                                        style="
                                          color: rgb(98, 98, 98);
                                          text-decoration: none;
                                          margin-top: 0px;
                                          margin-bottom: 0px;
                                        "
                                        >redware.io</a
                                      ></span
                                    >
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  colspan="4"
                  style="
                    border-bottom: 2px solid rgb(118, 118, 118);
                    padding-right: 1px;
                    padding-bottom: 13px;
                  "
                ></td>
              </tr>
              <tr>
                <td colspan="2" style="padding-top: 13px; padding-right: 1px">
                  <table
                    style="
                      margin: 0px;
                      box-sizing: border-box;
                      border-collapse: collapse;
                      border-spacing: 0px;
                    "
                  >
                    <tbody>
                      <tr>
                        <td style="padding-right: 1px">
                          <p style="margin: 0.75pt">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://redware.io/"
                              ><span
                                style="
                                  color: blue;
                                  font-family: Aptos, sans-serif;
                                  font-size: 12pt;
                                "
                                ><span
                                  style="
                                    text-decoration: none;
                                    text-underline: none;
                                  "
                                >
                                  <picture>
                                    <source
                                      srcset="
                                        https://pictures.redware.io/assinatura_email/logo_redware_113.webp   113w,
                                      "
                                      type="image/webp"
                                      sizes="(max-width: 1126px) 100vw, 1126px"
                                    />
                                    <img
                                      src="https://pictures.redware.io/assinatura_email/logo_redware_113.webp"
                                      width="50"
                                      height="56"
                                      border="0"
                                      v:shapes="Picture_x0020_10"
                                      uploadprocessed="true"
                                    />
                                  </picture> </span></span
                            ></a>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  colspan="2"
                  style="
                    padding-top: 13px;
                    padding-right: 1px;
                    padding-left: 13px;
                    vertical-align: middle;
                  "
                >
                  <table
                    style="
                      margin: 0px;
                      box-sizing: border-box;
                      border-collapse: collapse;
                      border-spacing: 0px;
                    "
                  >
                    <tbody>
                      <tr>
                        <td
                          style="
                            line-height: 0px;
                            padding-right: 1px;
                            width: 29px;
                          "
                        >
                          <p style="margin: 0.75pt">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.linkedin.com/company/redware-brasil/"
                              ><span
                                style="
                                  color: blue;
                                  font-family: Aptos, sans-serif;
                                  font-size: 1pt;
                                "
                                ><span
                                  style="
                                    text-decoration: none;
                                    text-underline: none;
                                  "
                                >
                                  <picture>
                                    <source
                                      srcset="
                                        https://pictures.redware.io/assinatura_email/linkedin_80.webp 80w,
                                        https://pictures.redware.io/assinatura_email/linkedin_84.webp 84w
                                      "
                                      type="image/webp"
                                      sizes="(max-width: 84px) 100vw, 84px"
                                    />
                                    <img
                                      src="https://pictures.redware.io/assinatura_email/linkedin_84.png"
                                      width="29"
                                      height="29"
                                      border="0"
                                      v:shapes="Picture_x0020_9"
                                      uploadprocessed="true"
                                    />
                                  </picture> </span></span></a
                            ><span
                              style="
                                font-family: Aptos, sans-serif;
                                font-size: 12pt;
                              "
                              ><span style="font-size: 1pt"></span
                            ></span>
                          </p>
                        </td>
                        <td style="padding-bottom: 1px; width: 3px"></td>
                        <td
                          style="
                            line-height: 0px;
                            padding-right: 1px;
                            width: 29px;
                          "
                        >
                          <p style="margin: 0.75pt">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.instagram.com/redwarebrasil/"
                              ><span
                                style="
                                  color: blue;
                                  font-family: Aptos, sans-serif;
                                  font-size: 1pt;
                                "
                                ><span
                                  style="
                                    text-decoration: none;
                                    text-underline: none;
                                  "
                                >
                                  <picture>
                                    <source
                                      srcset="
                                        https://pictures.redware.io/assinatura_email/instagram_80.webp 80w,
                                        https://pictures.redware.io/assinatura_email/instagram_84.webp 84w
                                      "
                                      type="image/webp"
                                      sizes="(max-width: 84px) 100vw, 84px"
                                    />
                                    <img
                                      src="https://pictures.redware.io/assinatura_email/instagram_84.png"
                                      width="29"
                                      height="29"
                                      border="0"
                                      v:shapes="Picture_x0020_8"
                                      uploadprocessed="true"
                                    />
                                  </picture> </span></span></a
                            ><span
                              style="
                                font-family: Aptos, sans-serif;
                                font-size: 12pt;
                              "
                              ><span style="font-size: 1pt"></span
                            ></span>
                          </p>
                        </td>
                        <td style="padding-bottom: 1px; width: 3px"></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td style="padding-right: 1px">
          <table
            style="
              margin: 0px;
              max-width: 600px;
              box-sizing: border-box;
              border-collapse: collapse;
              border-spacing: 0px;
            "
          >
            <tbody>
              <tr>
                <td
                  style="
                    line-height: 17px;
                    padding-top: 15px;
                    padding-right: 1px;
                    color: rgb(136, 136, 136);
                  "
                >
                  <p
                    style="
                      line-height: 17px;
                      margin: 1px;
                      font-family: Arial, sans-serif;
                      font-size: 11px;
                      color: rgb(136, 136, 136);
                    "
                  >
                    The content of this email is confidential and intended for the
                    recipient specified in message only. It is strictly forbidden
                    to share any part of this message with any third party,
                    without a written consent of the sender. If you received this
                    message by mistake, please reply to this message and follow
                    with its deletion, so that we can ensure such a mistake does
                    not occur in the future.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  <div>
    <span
      style="
        font-family: Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, Calibri,
          Helvetica, sans-serif;
        font-size: 12pt;
        color: rgb(0, 0, 0);
      "
      ><br
    /></span>
  </div>
  <span
    style="
      font-family: Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, Calibri,
        Helvetica, sans-serif;
      font-size: 12pt;
      color: rgb(0, 0, 0);
    "
  ></span>
  
  
    `;
  };

  const handleCopyToClipboard = async () => {
    const html = generateEmailHtml(formData);
    if (html) {
      try {
        // Criar um blob com o tipo MIME HTML
        const blob = new Blob([html], { type: "text/html" });

        // Criar um item de dados de área de transferência
        const data = [new ClipboardItem({ "text/html": blob })];

        // Copiar o HTML para a área de transferência
        await navigator.clipboard.write(data);

        toast({
          title: "HTML copiado para a área de transferência!",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (err) {
        console.error("Falha ao copiar: ", err);
        toast({
          title: "Falha ao copiar o HTML",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="md" mt={4}>
      <Button
        textColor={btnTextColor}
        bg={btnColor}
        onClick={handleCopyToClipboard}
        mb={4}
        isDisabled={!isFormComplete}  // Desabilita o botão se o formulário estiver incompleto
      >
        Copiar HTML
      </Button>
      <Box mt={4}>
        <Text fontWeight="bold" mb={2}>Instruções:</Text>
        <List spacing={2} styleType="decimal" pl={4}>
          <ListItem>Clique no botão "Copiar HTML".</ListItem>
          <ListItem>Abra o Outlook e vá até as configurações de assinaturas.</ListItem>
          <ListItem>Crie uma nova assinatura e cole o conteúdo copiado.</ListItem>
          <ListItem>Salve a assinatura.</ListItem>
        </List>
      </Box>
    </Box>
  );
};

HtmlGenerator.propTypes = {
  formData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    function: PropTypes.string.isRequired,
    telephone: PropTypes.string.isRequired,
    timestamp: PropTypes.string,
  }).isRequired,
};

export default HtmlGenerator;
